import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";



export const Nav = styled.nav`
background-color: #37d5d6;
background-image: linear-gradient(315deg, #37d5d6 0%, #36096d 74%);
height: 40px;
display: flex;
justify-content: start;
// padding: 0.2rem calc((100vw - 1000px) / 2);
padding: 16px;
z-index: 12;
`;

export const NavLink = styled(Link)`
color: #ffff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
text-transform: uppercase;
text-shadow: 0 0 15px #8e54e9;
transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
&.active {
	color: #23395d;
}
`;

export const SignOutLink = styled(Link)`
color: #ffff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&.active {
	color: #ffff;
}
`;

export const Bars = styled(FaBars)`
display: none;
color: #808080;
@media screen and (max-width: 768px) {
	display: block;
	position: relative;
	top: 0;
	right: 0;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
}
`;

export const NavMenu = styled.div`
display: flex;
text-align:right;
margin-left:auto;
margin-right:0;
justify-content: end;
// align-items: center;
// margin-right: -24px;
/* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
white-space: nowrap; */
@media screen and (max-width: 768px) {
	display: none;
}
`;

