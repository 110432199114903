import {React, useState, useEffect} from 'react';
import ADMETabs from './Tabs';
import './results.css';


function Results(props) {
    const [noshowResults, setShowResults] = useState(false);

    useEffect(() => {
      console.log(props.results)
      if (props.results.A!=''){
        setShowResults(true)
      }
    },[props.results])
  
  return (
    <div className='result-box'>
        {noshowResults && 
        <>
        <h2 className='result-title'>Prediction results: {props.results.job_name}</h2>
         </>
         }
         <ADMETabs results = {props.results}/>
    </div>
  )
}

export default Results