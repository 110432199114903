import React, { useContext , useState} from 'react'
import Button from '@mui/material/Button';
import './Upload.css';
import UserPool from '../../UserPool';
import {ProjectsContext} from '../Projects/Projects';

function Upload (props){
	const [create,join,project_name] = useContext(ProjectsContext);
	const [file,setFile] = useState("");


	const onFileChange = (event) =>{
		setFile(event.target.files[0]);
	}

	const uploadFileData = (event) => {
		event.preventDefault();		
		console.log(project_name);
    	let headers = new Headers();
		headers.set('Content-Type','multipart/form-data')
		let data = file
		let user = UserPool.getCurrentUser().username;
		let finalName = project_name.toUpperCase()+'_'+file.name
		props.parentCallback(project_name.toUpperCase()+'_'+file.name);
		fetch('https://o09fa0ao3h.execute-api.us-east-1.amazonaws.com/v1/protein-bucket/'+finalName, {
			method: 'PUT',
			body: data,
			headers:headers
		}).then(response => {
			// this.setState({msg: "File successfully uploaded"});
			alert('Successful Upload')
			// this.setState({uploaded:true});
			console.log('adasdasd')
		}).catch(err => {
			alert('Error: '+err)
			this.setState({error: err});
		});

	}

    return (
    <div className='updiv'>
      <input type="file" id="contained-button-file" onChange = {onFileChange}/>

<label htmlFor="contained-button-file">
        <Button variant="contained" onClick={uploadFileData} color="inherit" component="span" size="small">
          Upload {props.type}
        </Button>
</label>
{}
    </div>
    )
}

export default Upload;