import React, {useState, useContext} from 'react'
import UserPool from '../../UserPool';
import { AccountContext} from './Accounts';
import './auth.css'
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import { CognitoUserAttribute, CognitoUser } from "amazon-cognito-identity-js";
import  AWS from 'aws-sdk';
import { ConfigurationOptions } from 'aws-sdk';

const Signup=(props)=>{

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [code, setCode] = useState("");
  const [authenticate] = useContext(AccountContext);
  
  AWS.config.update({
    region: 'us-east-1',
    secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
    accessKeyId: 'AKIA5CHARLHLM66QLSIV'
});
  const dynamoClient = new AWS.DynamoDB.DocumentClient();

  const onSubmit = (event) => {
      event.preventDefault();
      var attributeList = [];
      var dataEmail = {
        Name:'email',
        Value : email
      };
      console.log(email);
      var attributeEmail  = new CognitoUserAttribute(dataEmail);
      attributeList.push(attributeEmail);
      console.log(attributeList);
      UserPool.signUp(username,password,attributeList,null, function(err,data){
          if (err){
              console.log('Error '+err);
              alert('Error '+err);
          }
          else{
            setConfirm(true);
            console.log(data);
          }
      });
  };

  const dynamoAdd = (pk) =>{
    var params = {
      TableName: 'tool_jobs',
      Item:{
      'username': pk,
      'Projects' : {
        'admin': [],
        'member': [],
      },
      }
      
    }
    dynamoClient.put(params, function(err,data){
      if (err){
        console.log('Error: ', err)
      } else {
        console.log('Success', data)
      }
    })
  };

  const onConfirm = (event) =>{
    var userData = {
      Username: username,
      Pool: UserPool,
    };
    var cognitoUser = new CognitoUser(userData);
    console.log(code)
  cognitoUser.confirmRegistration(code, true, function(err, result) {
	if (err) {
		alert(err.message || JSON.stringify(err));
		return;
	}
    console.log('Success confirm')
    dynamoAdd(username);
    authenticate(username,password)
      .then(data => {
          console.log('Logged In!', data);
          props.loggedIn(true);
          ;
      })
      .catch(err => {
          console.error('Failed to login', err);
          props.loggedIn(false);
      })
});
  }
  const handleClick = () => {
    props.toggle();
  };
  return (
    <div>
    <div className='modal'>
    <div className='modal-content'>
     <div className="login-form">
     <div className="form-box solid">
        <span className="close" onClick={handleClick}>
            &times;
          </span>
          {confirm &&
               <form onSubmit = {onConfirm}>
               {/* <label htmlFor='given_name'>First Name</label>
               <input
                   value = {given_name}
                   onChange = {(event) => setFN(event.target.value)}
               ></input>
               <label htmlFor='family_name'>Last Name</label>
               <input
                   value = {family_name}
                   onChange = {(event) => setLN(event.target.value)}
               ></input> */}
               <h1 className="login-text">Confirm Account</h1>
               <label class = 'label' htmlFor='code'>Code</label>
               <input className='login-box'
                   value = {code}
                   onChange = {(event) => setCode(event.target.value)}
               ></input>
               <br></br>
               <div class='label'>
             <Button onClick={onConfirm} variant='outlined'>Confirm Account</Button>
             </div>
     
       
               </form>
          }
        {!confirm &&
             <form onSubmit = {onSubmit}>
             <h1 className="login-text">Sign Up</h1>
             <label class = 'label' htmlFor='email'>Email</label>
             <input className='login-box'
                 value = {email}
                 onChange = {(event) => setEmail(event.target.value)}
             ></input>
             <br></br>
             <label class = 'label' htmlFor='username'>Username</label>
             <input className='login-box'
                 value = {username}
                 onChange = {(event) => setUsername(event.target.value)}
             ></input>
             <br></br>
             <label class = 'label'htmlFor='password'>Password</label>
             <input className='login-box'
                 value = {password}
                 onChange = {(event) => setPassword(event.target.value)}
             ></input>
             <br></br>
             <div class='label'>
             <Button onClick={onSubmit} variant='outlined'>Sign Up</Button>
             </div>
     
             </form>
        }
   
    </div>
    </div>
    </div>
    </div>
    </div>
  )
    
}

export default Signup