import {React, useState} from 'react'
import {Redirect} from 'react-router-dom'
import Signup from '../components/Auth/Signup'
import Login from '../components/Auth/Login'
import Status from '../components/Auth/Status'
import { Accounts } from '../components/Auth/Accounts';
import logo from '../assets/graphen_logo.png'
import './landing.css'
import Description from '../components/LandingPage/Description'
import Tools from '../components/LandingPage/Tools'
import {Helmet} from 'react-helmet';
import { Button } from '@mui/material';
import { createTheme } from '@mui/material';

function Landing (props){
  const theme = createTheme({
    shape:{
      borderRadius:20
    }
  })
  const [isShowSignup,setIsShowSignup] = useState(false);
  const [isShowLogin,setIsShowLogin] = useState(false);

  const handleSignupClick = () =>{
    setIsShowSignup((isShowSignup) => !isShowSignup);
  };
  const handleLoginClick = () => {
    setIsShowLogin((isShowLogin) => !isShowLogin);
  };
  const toggleSign= () =>{
    if (isShowSignup==true)
      setIsShowSignup(false);
  }
  const toggleLogin= () =>{
    if (isShowLogin==true)
      setIsShowLogin(false);
  }

  const sendtoAtom = (data) =>{
    props.isLoggedIn(data);
  }
  return (
    <div>
      <Helmet>
      background-color: #36096d;
background-image: linear-gradient(315deg, #36096d 0%, #37d5d6 74%);
      {/* <style>{"body { background-color: #0cbaba; background-image: linear-gradient(315deg, #0cbaba 0%, #380036 74%); }"}</style> */}
      <style>{"body { background-color: #37d5d6; background-image: linear-gradient(315deg, #37d5d6 0%, #36096d 74%); }"}</style> 
      </Helmet>
      {isShowSignup? <Signup loggedIn = {sendtoAtom} toggle = {toggleSign}/>:null}
      {isShowLogin? <Login loggedIn = {sendtoAtom} toggle = {toggleLogin}/>:null}
      <div className='headlogo'>
      <img src={logo}></img>
        <h1>Graphen Atom</h1>
      </div>
      
      <section class="dual-container">
       <div class="one">
         <h1>
           <Description/>
         </h1>
       </div>
       <div class="two">
       <Tools/>
       </div>
      </section>
      <div class = 'login'>
          <Button onClick={handleSignupClick} theme = {theme} size='large' variant ='contained' borderRadius='20px'>
            Get Started
          </Button>
          <br></br>
          <br></br>
         <span> Already have an account? <p style={{color:'blue',cursor:'pointer'}} onClick={handleLoginClick}>Sign in</p></span>
        </div>
    </div>
  )
}

export default Landing