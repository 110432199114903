import React, {useState, useContext} from 'react'
import { AccountContext} from './Accounts';
import UserPool from '../../UserPool';
import {Navigate} from 'react-router-dom';
import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import './auth.css'
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import {makeStyles} from "@material-ui/core/styles";



const Login=(props)=>{
  
  const [username, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [given_name, setFN] = useState("");
  const [family_name, setLN] = useState("");


  const [authenticate] = useContext(AccountContext);
  const onSubmit = (event) => {
      event.preventDefault();
      authenticate(username,password)
        .then(data => {
            console.log('Logged In!', data);
            props.loggedIn(true);
        })
        .catch(err => {
            console.error('Failed to login', err);
            props.loggedIn(false);
        })
  }; 
  const handleClick = () => {
    props.toggle();
  };

  return (
    
    <div>   
    <div className='modal'>
    <div className='modal-content'>
     <div className="login-form">
     <div className="form-box solid">
        <span className="close" onClick={handleClick}>
            &times;
          </span>
        <form onSubmit = {onSubmit}>
        <h1 className="login-text">Log In</h1>
        <label class='label' htmlFor='password'>Username</label>
        <input className='login-box'
             value = {username}  onChange = {(event) => setUser(event.target.value)}>
        </input>
        <br></br>
        <label class = 'label' htmlFor='password'>Password</label>
        <input className='login-box'
            value = {password}
            onChange = {(event) => setPassword(event.target.value)}
        ></input>
        <br></br>
        <div class='label'>
        <Button onClick={onSubmit} variant='outlined'>Login</Button>
        </div>

        </form>

    </div>
    </div>
    </div>
    </div>
    </div>
  )
    
}
export default Login