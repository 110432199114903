import {React, useState, useContext} from 'react';
import UserPool from '../../UserPool';
import {ProjectsContext} from './Projects';
import './projects.css'
import { Button } from '@mui/material';
import AWS from 'aws-sdk';

function Create(props) {

  const [inp_project_name, setProjectName] = useState("");

  const [create, join, project_name] = useContext(ProjectsContext);
  AWS.config.update({
    region: 'us-east-1',
    secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
    accessKeyId: 'AKIA5CHARLHLM66QLSIV'
});
  const dynamoClient = new AWS.DynamoDB.DocumentClient();
  let username = UserPool.getCurrentUser().username;
  const onSubmit = (event) => {
    event.preventDefault();
    create(inp_project_name);
    props.toggle();
  }
  const handleClick = () =>{
    console.log('testeateae');
    props.toggle();
  }
  return (
    <div className='modal'>
    <div className='modal-content'>
     <div className="login-form">
     <div className="form-box solid">
        <span className="close" onClick={handleClick}>
            &times;
          </span>
        <form onSubmit = {onSubmit}>
        <h1 className="login-text">Create Project</h1>
        <label class='label' htmlFor='password'>Project Name</label>
        <input className='login-box'
             value = {inp_project_name}  onChange = {(event) => setProjectName(event.target.value)}>
        </input>
        <br></br>
        {/* <label class = 'label' htmlFor='password'>Password</label>
        <input className='login-box'
            value = {password}
            onChange = {(event) => setPassword(event.target.value)}
        ></input> */}
        <br></br>
        <div class='label'>
        <Button onClick={onSubmit} variant='outlined'>Login</Button>
        </div>

        </form>

    </div>
    </div>
    </div>
    </div>
  )
}

export default Create;