import React from 'react'
import ba4 from '../assets/ba4.png'
import escape_ability from '../assets/escape_ability.png'
import hotspot from '../assets/hotspot.png'
import spike from '../assets/spike.png'
import './tools.css'

function Covid() {
  return (
    <div>
        <div class = 'grid-container-element'>
            <div class = "grid-child-element">
                <h2 className='textprot'>SARS-CoV-2 vaccine design</h2>
                <h4 className='textprot'>Analysis of mutations and antibody efficacy.</h4> <br />
            </div>
            <div class = 'grid-child-element'>
            <h2 className='textprot'>Mutation resistent design</h2>
                <p className='textprot'>Mutations in the coronavirus allow it to evade 
                the human immune system and treatement against viruses. Consequently, Graphen's aim is to use AI to develop a mutation tolerable vaccine.</p> <br />
            </div>
        </div>
        <div className='images'>
            <div className='img_container'>
                    <img width = {800} src = {spike}></img>
                </div>
                <div className='img_container'>
                    <img width = {800} src = {ba4}></img>
                </div>
                <div className='img_container'>
                    <img width = {800} src = {escape_ability}></img>
                </div>
                <div className='img_container'>
                    <img width = {800} src = {hotspot}></img>
            </div>
        </div>
        
    </div>
  )
}

export default Covid