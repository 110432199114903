import {React, useEffect, useMemo, useState, useContext} from 'react'
import './projects.css'
import AWS from 'aws-sdk';
import useTable from 'react-table';
import UserPool from '../../UserPool';
import { Table, TableRow } from '@mui/material';
import YourProjectItem from './YourProjectItem';
import { ProjectsContext} from './Projects'

function YourProjects(props) {

  const [adminProjects, setadminProjects] = useState([]); 
  const [projects, setProjects] = useState([]);
  AWS.config.update({
    region: 'us-east-1',
    secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
    accessKeyId: 'AKIA5CHARLHLM66QLSIV'
  });
  const dynamoClient = new AWS.DynamoDB.DocumentClient();
  useEffect(()=>{
    let user = UserPool.getCurrentUser().username;
    console.log('project user: ', user)
    var params = {
      TableName: "tool_jobs",
      Key: {
        username : user
      }
    }
    dynamoClient.get(params,function(err, data){
      if (err){
        console.log('Error: ', err);
      } else {
        console.log('Success project get', data);
        setadminProjects(data.Item['Projects']['admin'])
        setProjects(data.Item['Projects']['member'])
      }
    })
},[]);
  
  return (
    <div class = 'project-container'>
    <div class = 'projectsbox'>
      <div class = 'proj-list'>
      <div className='proj-list-title'>
      <h4 className='textjob'>Created Projects</h4>
      </div>
      {adminProjects.map(project => <YourProjectItem name = {project} admin = {true}></YourProjectItem>)}<br></br>
      </div>
      </div>
    <br></br>
      <div class = 'projectsbox'>
      <div class = 'proj-list'>
      <div className='proj-list-title'>
      <h4 className='textjob'>Member Projects</h4>
      </div>
      {projects.map(project => <YourProjectItem name = {project} admin = {false}></YourProjectItem> )}<br></br>
      </div>
      </div>
      </div>
  )
}

export default YourProjects;