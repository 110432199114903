import './App.css';
import React, {useRef, useEffect} from 'react';
// import Atom from './pages/Atom';
import Atom from './pages/Atom';
import {Projects} from './components/Projects/Projects';
import {Accounts} from './components/Auth/Accounts';

function App() {

  return (
    <Accounts>
    <Projects>
    <Atom></Atom>
    </Projects>
    </Accounts>
  );
}

export default App;
