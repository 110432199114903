import React from 'react'
import './styles.css'
import {ReactComponent as AtomTools} from '../../assets/12_tools_original.svg'

function Tools() {
  return (
    <div className='text'>
        <AtomTools></AtomTools>
    </div>
  )
}

export default Tools