import React from 'react'
import { TextField } from '@mui/material';
import './Param.css'
import { OtherHousesOutlined } from '@mui/icons-material';

class Param extends React.Component{
 
  setParam = (event) => {
    this.props.parentCallback(event.target.value);
    event.preventDefault();
  }
  render(){
    return (
      <div className='param'>
      <TextField label = {this.props.parType} onChange = {this.setParam} id = "outlined"/>
      </div>

      
    )
  }
}

export default Param