import {React, useState} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DataCard from './DataCard';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding:1,marginTop:-1}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function ADMETabs(props) {
  const [value, setValue] = useState(0);
  const [dataMap,setDataMap] =useState({
    'A':{
        'Bioavailability':['Bioavailability_Ma','', '-'],
        'Human Intestinal Absorption (HIA)': ['HIA_Hou','','-'],
        'Cell Effective Permeability (Caco-2)':['Caco2_Wang','cm/s','1e-8 - 1e-4'],
        'Hydration Free Energy':['HydrationFreeEnergy_FreeSolv','kcal/mol','-25.47 - 3.43'],
        'Lipophilicity':['Lipophilicity_AstraZeneca','LogD7.4','0 - 3'],
        'Solubility':['Solubility_AqSolDB','LogS','-4 - 0 (Soluble)'],
    },
    'D':{
        'Blood Brain Barrier (BBB)':['BBB_Martins','','-'],
        'Plasma Protein Binding Rate (PPBR)': ['PPBR_AZ','%','-'],
        'Volume of Distribution at steady state (VDss)':['VDss_Lombardo','l/kg','0.04 - 700'],
    },
    'M':{
        'CYP P450 1A2 Inhibition':['CYP1A2_Veith','','-'],
        'CYP P450 2C19 Inhibition':['CYP2C19_Veith','','-'],
        'CYP P450 2C9 Inhibition':['CYP2C9_Veith','','-'],
        'CYP P450 3A4 Inhibition':['CYP3A4_Veith','','-'],
        'CYP P450 2D6 Inhibition':['CYP2D6_Veith','','-'],
        'CYP2C9 Substrate':['CYP2C9_Substrate_CarbonMangels','','-'],
        'CYP2D6 Substrate':['CYP2D6_Substrate_CarbonMangels','','-'],
        'CYP3A4 Substrate':['CYP3A4_Substrate_CarbonMangels','','-'],
    },
    'E':{
        'Hepatocyte Clearance': ['Clearance_Hepatocyte_AZ','mL/min/kg','1 - 200'],
        'Microsome Clearance':['Clearance_Microsome_AZ','mL/min/kg', '1 - 200'],
        'Half Life': ['Half_Life_Obach','minutes'],
    }
  });

  const[description,setDescription] =useState({
    'A':"Measures how a drug travels from the site of administration to site of action.",
    'D':"How a drug moves to and from the various tissues of the body and the amount of drugs in the tissues.",
    'M':"Measures how specialized enzymatic systems breakdown the drugs and it determines the duration and intensity of a drug's action.",
    'E':"The removal of drugs from the body using various different routes of excretion, including urine, bile, sweat, saliva, tears, milk, and stool.",
    

  })

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex',backgroundColor:'transparent'}}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider'}}
      >
        <Tab label="Absorption" {...a11yProps(0)} />
        <Tab label="Distribution" {...a11yProps(1)} />
        <Tab label="Metabolism" {...a11yProps(2)} />
        <Tab label="Excretion" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <DataCard data = {props.results.A} mapping = {dataMap.A} desc = {description.A}></DataCard>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <DataCard data = {props.results.D} mapping = {dataMap.D} desc = {description.D}></DataCard>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <DataCard data = {props.results.M} mapping = {dataMap.M} desc = {description.M}></DataCard>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <DataCard data = {props.results.E} mapping = {dataMap.E} desc = {description.E}></DataCard>
      </TabPanel>
    </Box>
  );
}


        
        
        