import { Button } from '@mui/material';
import React from 'react';
import './Joblist.css'
// import AWS from 'aws-sdk';
import arr from '../../assets/right-arrow.png'
import AWS from 'aws-sdk';

function Job(props) {
const [status, setStatus] = React.useState(0);
const [tool,setTool] = React.useState(0);
const [link,setLink] = React.useState('');
const [results,setResults] = React.useState({});

AWS.config.update({
  region: 'us-east-1',
  secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
  accessKeyId: 'AKIA5CHARLHLM66QLSIV'
});

const dynamoClient = new AWS.DynamoDB.DocumentClient();

const sendLink = (event) =>{
  props.parentCallback(link);
  event.preventDefault();
}

const sendAdmeData = (event) =>{
  props.parentCallback(results);
  event.preventDefault();
}


const checkUrl =(url) =>{
    var request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.send();
    request.onload = function() {
      var req_status = request.status;
      console.log(req_status);
      console.log(request.status);
      if (request.status == 200) 
      {
        console.log("url exists");
        setStatus(1);
      } else {
        console.log("url doesn't exist");
        setStatus(2);
      }
    }
}

const checkDynamo = ()=>{
  var params = {
    TableName: "adme_results",
    Key: {
      job_name: props.id
    }
  }
  dynamoClient.get(params, function(err, data){
    if (err){
      console.log('Adme job still running: ',err);
      setStatus(2);
    } else {
      console.log('Adme job is complete!', data);
      if (JSON.stringify(data) === '{}'){
        console.log('Adme job still running');
        setStatus(2);
      }
      else{
        console.log(data);
      setResults(data.Item);
      setStatus(3);
      }
      
    }
  });
}
const jobStatus = (id) =>{
    // var dummylink = 'https://fastabucket.s3.amazonaws.com/id_0a5ba2a51_out.pdb'
    if (status != 0){
        setStatus(0);
    }
    else if (props.tool == 'Protein_Structure_Prediction'){
        checkUrl(link);
    }
    else if (props.tool == 'Drug_Development'){
        checkDynamo();
    }
  
}

const jobDriver = (id) =>{
  if (status != 0){
    setStatus(0);
    }
    else if (props.tool == 'Drug_Development'){
      checkDynamo();
    }
    else if (props.tool == 'Protein_Structure_Prediction'){
      setLink('https://protein-bucket.s3.amazonaws.com/'+id+'_out.pdb')
      checkUrl(link);
    }
    
}
  return (
    <div  class = 'button'>
        
        <p class = "btnText">{props.id}</p>
        <div  class="btnTwo">
          <p  onClick={(e)=>jobDriver(props.id)}class="btnText2"><img src={arr} class = 'center' height={38} width={38}/></p>
        </div>
        <div class = 'buttonStatComp'>
        {status ==1 && (
            <>
          <p class = 'btnTextFin'>Prediction complete!</p>
          <a href={link}>Download pdb</a>
          <Button onClick = {sendLink}>View</Button>
          </>
          )}
        </div>
        <div class = 'buttonStat'>
          {status == 2 && (
          <p class = 'btnTextRun'>
              Job still running
          </p>
          )}
        </div>
        <div class = 'buttonStatComp'>
          {status == 3 && 
          (<>
          <p class = 'btnTextFin'>Prediction complete!</p> 
          <Button onClick = {sendAdmeData}>View Analysis</Button>
          </>
          )}
        </div>
  </div>
  )
}

export default Job