import {React, useState, useContext} from 'react'
import './Joblist.css'
import Job from './Job.js'
import './Joblist.css'
import AWS from 'aws-sdk';
import Button from '@mui/material/Button'
import UserPool from '../../UserPool'
import {ProjectsContext} from '../Projects/Projects';
const Joblist = (props) => {
  
  const [joblist,setList] = useState("");
  const [create,join,project_name] = useContext(ProjectsContext);
  const sendLink = (event) =>{
   props.parentCallback(event);
    
  }

  AWS.config.update({
      region: 'us-east-1',
      secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
      accessKeyId: 'AKIA5CHARLHLM66QLSIV'
  });

  const dynamoClient = new AWS.DynamoDB.DocumentClient();

  const clearJobs = ()=>{
    console.log('ji' ,joblist)
    console.log('gay',props)
    let user = UserPool.getCurrentUser().username;
    var updateParams = {
      TableName: "projects_jobs",
      Key: {
        project_name: project_name,
      },
      UpdateExpression: "set #tool = :t",
      ExpressionAttributeNames:{
        "#tool": props.tool,
      },
      ExpressionAttributeValues: {
        ":t": [],
      }
    }
    dynamoClient.update(updateParams, function(err,data){
      if (err){
        console.log('Error: ',err)
      } else {
        console.log('Success update', data)
        setList([]);
        window.location.reload();
      }
    })
  }
  return (
    <div key = {joblist} class ='listbox'>
      <div class='list'>
      <div className='list-title'>
      <h4 className='textjob'>Your Jobs</h4>
      <Button onClick = {clearJobs} size='small' color='error'>Clear</Button>
      </div>
      {props.length == 0 &&(
        <h3><i>No jobs queued</i></h3>
      )}
      {props.list.map(job => <Job tool = {props.tool} id = {job} parentCallback = {sendLink}></Job>)}
      </div>
      </div>
  )
}

export default Joblist