import {React, useState, useContext, useEffect} from 'react'
import {AccountContext} from '../components/Auth/Accounts'
import {ProjectsContext} from '../components/Projects/Projects'
import UserPool from '../UserPool';
import { Button } from '@mui/material';
import './Homepage.css'
import Create from '../components/Projects/Create';
import YourProjects from '../components/Projects/YourProjects';
import AddUser2 from '../components/Projects/AddUser2';
import ManageUsers from '../components/Projects/ManageUsers';
import AWS from 'aws-sdk';


function Homepage() {
  const [user,setUser] = useState("");
  const [isShowCreateProject,setIsShowCreateProject] = useState(false);
  const [isShowProjects, setIsShowProjects] = useState(false);
  const [create,join, project_name, deleteProj] = useContext(ProjectsContext);
  const [isAdmin, setisAdmin] = useState(false);

  AWS.config.update({
    region: 'us-east-1',
    secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
    accessKeyId: 'AKIA5CHARLHLM66QLSIV'
  });
  const dynamoClient = new AWS.DynamoDB.DocumentClient();

  useEffect(()=>{
     let user_name = UserPool.getCurrentUser().username;
     console.log(user_name)
     setUser(UserPool.getCurrentUser().username);
     if (project_name != null){
      var params = {
        TableName : "projects_jobs",
        Key: {
          project_name : project_name
        }
      }
      dynamoClient.get(params,function(err, data){
        if (err){
          console.log('Error: ', err);
        } else {
          console.log('Success admin get', data);
  
          if (data.Item['Admin'].includes(user_name))
            setisAdmin(true);
          
        }
      })
      }
     
  },[user,isAdmin])

  const togglecreateProject = () =>{
    setIsShowCreateProject(!isShowCreateProject);
  }

  const togglejoinProject = () =>{
    setIsShowProjects(!isShowProjects);
  }
  
  return (
    <>
    <br></br>
    <br></br>
    <div class = 'title'>
      Hi {user}! Welcome to the Atom Toolkit.
      <div>
      Join or create a project to access the toolkit in the Navbar
      </div>
      <div>
        {project_name != null ? <><h2>Current Project : {project_name}</h2></>:null}
      </div>
    </div>
    <div class = 'title'>
    <Button onClick = {togglecreateProject} color = 'primary' variant = 'contained' size = 'small'>Create New Project</Button>
    </div>
    {isShowCreateProject? <Create  toggle = {togglecreateProject}></Create>:null}
    <div style={{width: "100%"}}>
    <div class = 'left-div'>
    {
      isAdmin && <><div class = 'item-left'>
            <AddUser2></AddUser2>
            </div>
            <div class = 'item-left'>
            <ManageUsers></ManageUsers>
            </div>
            </>
    }
    </div>
    <div class ='right-div'>
    <div class = 'item-right'>
    <YourProjects></YourProjects>
    </div>
    </div>
    </div>
    </>
  )
}

export default Homepage;