import React, {createContext} from 'react';
import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import UserPool from '../../UserPool';

const AccountContext = createContext();

function Accounts(props) {
    const getSession = async () =>
        await new Promise((resolve, reject)=>{
            const user = UserPool.getCurrentUser();
            if (user) {
                user.getSession((err,session) => {
                    if (err) {
                        reject();
                    }else{
                        resolve(session);
                    }
                })
            }else{
                reject();
            }
        });

    const authenticate = async (email,password) => {
        await new Promise((resolve, reject)=>{
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool,
        });
        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        })
        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log("onSuccess: ", data);
                resolve(data);
                alert('login success');
                window.localStorage.setItem("Logged",true);
            },
            onFailure: (err) => {
              console.log("onFailure: ",err);
              alert('login fail');
              reject(err);
            },
            newPasswordRequired: (data) => {
                console.log("newPasswordRequired: ",data);
            }
        });
        
    });
    };
  return (
    <AccountContext.Provider value ={[
        authenticate,getSession
    ]}>
        {props.children}
    </AccountContext.Provider>
  )
}

export {Accounts, AccountContext};