import {React, useContext} from 'react';
import './projects.css';
import { Button } from '@mui/material';
import { ProjectsContext } from './Projects';
import AWS from 'aws-sdk';
import UserPool from '../../UserPool';

AWS.config.update({
  region: 'us-east-1',
  secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
  accessKeyId: 'AKIA5CHARLHLM66QLSIV'
});
const dynamoClient = new AWS.DynamoDB.DocumentClient();


function YourProjectItem(props) {
  const [create,join,project_name,deleteProj,deleteProjTable] = useContext(ProjectsContext); 
  const switchProject = ()=>{
    console.log('projecti is ',props.name)
    join(props.name);
    window.location.reload();
  }
  
  const deleteProject = ()=>{
      return deleteProj(props.name).then(deleteProjTable(props.name));
      
  }
  return (
    <>
    <div class = 'proj-list-item'>
        {props.name}
        <div class = 'proj-button'>
        {props.admin && <Button onClick = {deleteProject} size='small' color='error'>Delete</Button>}
        </div>
        <div class = 'proj-button'>
        <Button onClick = {switchProject} size='small' color='primary'>Join</Button>
        </div>
        </div>
    </>
  )
}

export default YourProjectItem