import {React,useState, useContext, useEffect} from "react";
import { Nav, NavLink, NavMenu, SignOutLink }
	from "./NavbarElements";
import logo from '../../assets/graphen_logo.png'
import './nav.css';
import { ProjectsContext } from "../Projects/Projects";

const Navbar = () => {
	const signOut = () =>{
		window.localStorage.removeItem("Logged");
		window.localStorage.removeItem("Projected");
		window.href('/')
	}
	const [create,join,project_name] = useContext(ProjectsContext);
	const [joined,setJoined] = useState(false);
	useEffect(()=>{
		if (project_name!=null){
			setJoined(true);
			console.log(project_name);
		}
		console.log(joined);
	})
return (
	<>
	
	<Nav>
	<img src = {logo} ></img>
	<h3 className="atomlogo">Graphen Atom</h3>
		<NavMenu>
		<NavLink to="/" activeStyle>
			<p className="navtext">HOME</p> 
		</NavLink>
		{ joined ?
		<NavLink to="/tools" activeStyle>
			<p className="navtext">TOOLS</p> 
		</NavLink>:<></>
		}
		{/* <NavLink to="/protein" activeStyle>
		<p className="navtext">PROTEIN</p> 
		</NavLink>			
		<NavLink to="/adme" activeStyle>
		<p className="navtext">ADME</p> 
		</NavLink> */}
		<SignOutLink to ="/"  onClick = {signOut}>
		<p className="navtext">SIGN OUT</p> 
		</SignOutLink>
		</NavMenu>
	</Nav>
	</>
);
};

export default Navbar;

