import {React, useEffect, useState} from 'react'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper} from '@mui/material';
import { create } from '@mui/material/styles/createTransitions';

function DataCard(props) {

const [rows,setRows] = useState([]);

const createData = (subtask,value,unit,range) =>{
    return {subtask,value,unit,range};
}
const colorMap = {
  'Active': 'green',
  'Inactive': 'red'
}
function getColor(key){
  switch (key){
    case 'Active':
      return 'green';
    
    case 'Inactive':
      return 'red';
    default:
      return 'black';

    }
  }
useEffect(() => {
    if (props.data != ''){
      console.log(props);
    let tempRows = [];
    var unit = '';
    for (const [key,value] of Object.entries(props.mapping)){

        for (const [test_type,prediction] of Object.entries(props.data[value[0]])){
            if (prediction ==1)
              var display_prediction = 'Active';
            else if (prediction == 0)
              var display_prediction = 'Inactive';
            else
              var display_prediction = parseFloat(prediction).toFixed(3);
              unit = value[1]
            var range = value[2]
            tempRows.push(createData(key,display_prediction,unit, range))
        }
    }
    setRows(tempRows);
    }
    
}, [props])
  return (
    <div className='card shadow2'>
    <h4 className='description' >{props.desc}</h4>
    <TableContainer component={Paper}>
      <Table sx={{ backgroundColor:'white' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx ={{fontSize:20, color:'#4169E1', fontFamily:'Poppins', fontWeight:'bold'}}>Sub-task</TableCell>
            <TableCell sx ={{fontSize:20, color:'#4169E1', fontFamily:'Poppins', fontWeight:'bold'}}>Prediction</TableCell>
            <TableCell sx ={{fontSize:20, color:'#4169E1', fontFamily:'Poppins', fontWeight:'bold'}}>Valid Range</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row) => (
        <TableRow
          key={row.subtask}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row" sx ={{fontFamily:'Poppins', fontWeight:'bold', color:'black'}}>{row.subtask}</TableCell>
            <TableCell sx ={{fontFamily:'Poppins', fontWeight:'bold', color:getColor(row.value)}}>{row.value} {row.unit}</TableCell>
            <TableCell sx ={{fontFamily:'Poppins', fontWeight:'bold', color:getColor(row.value)}}>{row.range}</TableCell>
        </TableRow>
      ))}
        </TableBody>
      </Table>
    </TableContainer>
</div>
  )
}

export default DataCard