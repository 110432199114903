
import React from 'react';
import Upload from '../components/Protein/Upload';
import Viewer from '../components/Protein/StructViewer';
import Button from '@mui/material/Button';
import Param from '../components/Protein/Param';
import Joblist from '../components/Joblist/Joblist';
import Checkbox from '@mui/material/Checkbox';
import AWS from 'aws-sdk';
import UserPool from '../UserPool';
import './tools.css'
import { ReportGmailerrorred } from '@mui/icons-material';
import { ConstructionOutlined } from '@mui/icons-material';
import { useEffect } from 'react';
import { ProjectsContext } from '../components/Projects/Projects';

const ProteinPred = () => {
  const [user,setUser] = React.useState("");
  const [cycle, setCycle] = React.useState(10000);
  const [structs, setStructs] = React.useState(2);
  const [filename, setFile] = React.useState("dummy");
  const [jobs, setJobs]= React.useState([]);
  const [status, setStatus] = React.useState(0);
  const [displayID, setDisID] = React.useState('');
  const [uid,setUID] = React.useState('3fa85f64-5717-4562-b3fc-2c963f66afa6')
  const [relax, setRelax] = React.useState(false)
  const [create,join,project_name] = React.useContext(ProjectsContext);

  useEffect(() =>{
    var params = {
      TableName: "projects_jobs",
      Key: {
        project_name:project_name,
      }
    }
    dynamoClient.get(params, function(err, data){
      if (err){
        console.log('Error: ',err)
      } else {
        console.log('Success get', data)
        updateJobs(data.Item['Protein_Structure_Prediction']);
      }
  })
},[]);


  const params = {
    cycles : "",
    structures: ""
  }

  AWS.config.update({
    region: 'us-east-1',
    secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
    accessKeyId: 'AKIA5CHARLHLM66QLSIV'
});

  const dynamoClient = new AWS.DynamoDB.DocumentClient();
  const radioHandler = (status) => {
    setStatus(status);
    
};

const parBackCycle = (cycle) =>{
  setCycle(cycle);
  console.log(cycle);

}

const viewLink = (link) =>{
  setDisID(link);


}

const setFileName = (filename) =>{
  setFile(filename);

}

const parBackStruct = (structs) =>{
  setStructs(structs);
}
const parBackUID = (uid) =>{
  setUID(uid);
}
const handleRelaxCheck = (event) =>{
  console.log('relax value', event.target.checked );
  setRelax(event.target.checked);
}
const updateJobs = (jobs) =>{
  setJobs(jobs);
}

const dynamoUpdate = (key) =>{
var params = {
    TableName: "projects_jobs",
    Key: {
      project_name: project_name
    }
  }
  dynamoClient.get(params, function(err, data){
    if (err){
      console.log('Error: ',err)
    } else {
      console.log('Success get', data)
      var protJobList = data.Item['Protein_Structure_Prediction'];
      console.log(protJobList);
      if (protJobList.indexOf(key) >=0 ){
        alert('This job has already been submitted!');
      }
      else{

        protJobList.push(key);
        var updateParams = {
          TableName: "projects_jobs",
          Key: {
            project_name:project_name,
          },
          UpdateExpression: "set Protein_Structure_Prediction = :t",
          ExpressionAttributeValues: {
            ":t": protJobList
          }
        }
        dynamoClient.update(updateParams, function(err,data){
          if (err){
            console.log('Error: ',err)
          } else {
            console.log('Success update', data)
            console.log('ttt',protJobList);
            updateJobs(protJobList);
          }
        })

      }


    }
  })
}


const generate = (cycle, structs,filename) => {
  if (status == 1){
    fetch('https://o09fa0ao3h.execute-api.us-east-1.amazonaws.com/v1/rosetta?dev=False&cycles='+cycle+'&structs='+structs+'&file='+filename, {
      method: 'POST'
    }).then(response => {
      alert('Job submitted for generation');
      var key = filename.split(".")[0];
      dynamoUpdate(key);
      console.log('Job submitted for generation');
    }).catch(err => {
      alert('Error: '+err)
    });  
  }
  else if (status == 2){
    alert('Submitting job for generation')
    var key = filename.split(".")[0];
    dynamoUpdate(key);
    var body = {
      'uid': uid,
      'status': 'in_progress',
      'user_name': project_name,
      'name': key,
      'relax' :relax
    }
    fetch('http://172.254.87.244:50000/predictor', {
      method: 'POST',
      body: JSON.stringify(body)
    }).then(response => {
      console.log('hi');
      console.log(response.json())
    }).catch(err => {
      console.log('Error: '+err)
      console.log('Error response from server. Protein prediction may still be running.')
    });  
  }
  
}

  return (
    <div>
      <div class = 'grid-container-element'>
          <div class = "grid-child-element">
          <h2 className='textprot'>Protein Structure Prediction</h2>
       
       <h4 className='textprot'>Please upload the FASTA file with the RNA sequence</h4> <br />
          <Upload parentCallback = {setFileName} type = {"fasta"}></Upload>
            <div class ='container'>
            <input type="radio" value="graphen" name="Rosetta"  checked = {status == 1} onClick={(e) => radioHandler(1)} /> Rosetta<br></br>
            <input type="radio" value="graphen" name="Graphen" checked = {status == 2}  onClick={(e) => radioHandler(2)} /> Graphen
            </div>
            
              <div class='container'>
              {status ==1 && (
              <>
                <Param parType = {"Cycles"} parentCallback = {parBackCycle}></Param>
                <Param parType = {"Structures"} parentCallback = {parBackStruct}></Param>
              </>
              )}
              <br></br>
              {status ==2 && (
              <> 
                <Param parType = {"UID"} parentCallback = {parBackUID}></Param>
      
                Relax <Checkbox checked={relax} onChange = {handleRelaxCheck}></Checkbox>
              </>
              )}
              <br></br>
              {status !=0 &&
              (
              <Button onClick = {(e) => generate(cycle,structs, filename)} variant="contained" color="success" component="span" size="large">
              Generate
            </Button>)
              }
              </div>
          </div>
          
       
        <div class = 'grid-child-element'>
        <Joblist tool = {'Protein_Structure_Prediction'} list = {jobs} parentCallback = {viewLink}></Joblist>
        </div>
      </div>
       <Viewer link = {displayID}></Viewer>
    </div>
  );
};
  
export default ProteinPred;