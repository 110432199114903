import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import { Accounts } from '../components/Auth/Accounts';
import Navbar from '../components/Navbar';
import ProteinPred from './proteinPred';
import Landing from './landing';
import ADME from './ADME';
import Homepage from './Homepage';
import Tools from './Tools';
import Covid from './Covid';

function Atom() {

    const [status, setStatus] = useState(false);
    const [loggedIn,setLoggedIn] = useState(false);

    useEffect(() => {
      setLoggedIn(window.localStorage.getItem("Logged"));
    },[]);
    
    function loginChange(data) {
    setLoggedIn(window.localStorage.getItem("Logged"));
    console.log(loggedIn);
    if (loggedIn === true) {
      setStatus(true);
    }
    else {
      setStatus(false);
    }
    console.log(status);
  }
  return (
    <>
    {
    !loggedIn
    ? 
    <>
      <Router>
        <Routes>
          <Route path = '/' element = {<Landing isLoggedIn = {loginChange}/>}/>
        </Routes>
      </Router>
    </>
    : 
    <>
    <Accounts>
      <Router>
        <Navbar />
          <Routes>
            <Route path ="/" element = {<Homepage/>}/>
            <Route exact path='/tools' element={<Tools/>} />
            <Route  exact path='/protein'  element={<ProteinPred />} />
            <Route exact path='/adme' element={<ADME/>} />
            <Route exact path='/covid' element={<Covid/>} />
          </Routes>
        </Router>
    </Accounts>
    </>
}
    </>
  )
}

export default Atom