import {React, useState,useEffect, useContext} from "react";
import { ProjectsContext } from "../components/Projects/Projects";
import { Button } from "@mui/material";
import Joblist from '../components/Joblist/Joblist'
import Upload from "../components/Protein/Upload";
import Results from '../components/ADME/Results'
import AWS from 'aws-sdk';
import './tools.css';


AWS.config.update({
  region: 'us-east-1',
  secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
  accessKeyId: 'AKIA5CHARLHLM66QLSIV'
});

const dynamoClient = new AWS.DynamoDB.DocumentClient();

function ADME(){

  const [filename, setFile] =useState("dummy");
  const [jobs, setJobs]= useState([]);
  const [,,project_name] = useContext(ProjectsContext);
  const [results, setResults] = useState({
    'A':'',
    'D':'',
    'M':'',
    'E':'',
  });

  useEffect(() =>{
        var params = {
          TableName: "projects_jobs",
          Key: {
            project_name:project_name,
          }
        }
        dynamoClient.get(params, function(err, data){
          if (err){
            console.log('Error: ',err)
          } else {
            console.log('Success get', data)
            setJobs(data.Item['Drug_Development']);
          }
      })
      //eslint-disable-next-line
    },[results]);

  function generate() {
    alert('Submitting adme job for generation');
    var key = filename.split(".")[0];
    dynamoUpdate(key);
    fetch('http://172.254.87.242:2000/runadme?file=' + key, {
      method: 'POST'
    }).then(response => {
      alert('Adme job'+key+'has been completed');
    }).catch(err => {
      alert('Console log: ' + err);
    });


    // fetch('https://o09fa0ao3h.execute-api.us-east-1.amazonaws.com/v1/adme?file=' + filename, {
    //   method: 'POST'
    // }).then(response => {
    //   alert('adme job submitted for generation');
    //   var key = filename.split(".")[0];
    //   dynamoUpdate(key);
    //   console.log('Job submitted for generation');
    // }).catch(err => {
    //   alert('Error: ' + err);
    // });
  }

  const dynamoUpdate = (key) =>{
    var params = {
        TableName: "projects_jobs",
        Key: {
          project_name: project_name
        }
      }
      dynamoClient.get(params, function(err, data){
        if (err){
          console.log('Error: ',err)
        } else {
          console.log('Success get', data)
          var admeJobList = data.Item['Drug_Development'];
          console.log(admeJobList);
          if (admeJobList.indexOf(key) >=0 ){
            alert('This job has already been submitted!');
          }
          else{
    
            admeJobList.push(key);
            var updateParams = {
              TableName: "projects_jobs",
              Key: {
                project_name:project_name,
              },
              UpdateExpression: "set Drug_Development = :t",
              ExpressionAttributeValues: {
                ":t": admeJobList
              }
            }
            dynamoClient.update(updateParams, function(err,data){
              if (err){
                console.log('Error: ',err)
              } else {
                console.log('Success update', data)
                console.log('ttt',admeJobList);
                setJobs(admeJobList);
              }
            })
          }
        }
      })
    }


  return (
    <div>
      <div className="digimage">
      </div>
      <div class = 'grid-container-element'>
        <div class = "grid-child-element">
          <h2 className='textprot'>ADME Prediction</h2>
          <h4 className='textprot'>Please upload / enter SMILES</h4> <br />
          <div>
          <Upload parentCallback = {setFile} type = {"smiles"}></Upload>
          <br></br>
          <div class = 'smiles' >
          <Button onClick = {(e) => generate(filename)} variant="contained" color="success" component="span" size="small">
                Generate Predictions
          </Button>
          </div>
          </div>
       </div>
      <div class = 'grid-child-element'>
      <Joblist tool = {'Drug_Development'} list = {jobs} parentCallback = {setResults}></Joblist>
      </div>
      </div>
      <Results results = {results}></Results>
    </div>
  );
};

export default ADME;