import React, {createContext, useEffect, useState} from 'react'
import AWS from 'aws-sdk';
import { UserAgent } from 'amazon-cognito-identity-js';
import UserPool from '../../UserPool';

const ProjectsContext = createContext();


function Projects(props) {
  const [project_name,setProjectName] = useState();
  const [isUsersDel,setisUsersDel] = useState(false);
  const [isAdminDel,setisAdminDel] = useState(false);

  AWS.config.update({
    region: 'us-east-1',
    secretAccessKey: '+Sg4E3onsY59A1Kbu4KQzPLi+E0uJDU2Q/UaQUb6',
    accessKeyId: 'AKIA5CHARLHLM66QLSIV'
  });
  const dynamoClient = new AWS.DynamoDB.DocumentClient();

  useEffect(() =>{
    setProjectName(window.localStorage.getItem('Projected'));
},[project_name]);

  const create = async (inp_project_name) =>{
    let username = UserPool.getCurrentUser().username;
    await new Promise((resolve, reject) =>{
      var params = {
        TableName: 'projects_jobs',
        Item:{
        'project_name':inp_project_name,
        'Protein_Structure_Prediction': [],
        'Protein_Function_Prediction': [],
        'Antibody_Developability':[],
        'Molecular_Interaction': [],
        'Biological_Network': [],
        'Dynamic_Graph': [],
        'Molecular_Force_Field': [],
        'Mutation_Identification': [],
        'Affinity_Prediction': [],
        'Mutation_Intelligence': [],
        'Drug_Development': [],
        'Immunal_Formation': [],
        'Progress_Prediction': [],
        'MultiOmics_Analysis': [],
        'Users' : [],
        'Admin': [username],
        'Archive':false
        }
        
      }
      dynamoClient.put(params, function(err,data){
        if (err){
          console.log('Error: ', err)
        } else {
          setProjectName(inp_project_name);      
          window.localStorage.setItem("Projected",inp_project_name);    
          console.log('Success', data)

    var userparams = {
      TableName: 'tool_jobs',
      Key: {
        username: username
      }
    }

    dynamoClient.get(userparams, function(err, data){
      if (err){
        console.log('Error in user update: ',err)
      } else {
        console.log('Success get user', data)
        var adminProjects = data.Item['Projects']['admin'];
        var memberProjects = data.Item['Projects']['member'];
        console.log(adminProjects);
        if (adminProjects.indexOf(inp_project_name) >=0 ){
          alert('This project has already been added!');
        }
        else{
  
          adminProjects.push(inp_project_name);
          let projects = {
            'admin':adminProjects,
            'member':memberProjects
          }
          var updateParams = {
            TableName: "tool_jobs",
            Key: {
              username: username,
            },
            UpdateExpression: "set Projects = :t",
            ExpressionAttributeValues: {
              ":t": projects
            }
          }
          dynamoClient.update(updateParams, function(err,data){
            if (err){
              console.log('Error: ',err)
            } else {
              console.log('Success update', data)
              window.location.reload();
            }
          })
  
        }
  
  
      }
    })
    
        }
      });
    });
    
  }



const deleteProj=async(inp_project_name)=>{
  console.log('ji', inp_project_name);
  await new Promise((resolve, reject) => { 
  var projectParams = {
    TableName: 'projects_jobs',
    Key: {
      project_name: inp_project_name
    }
  }
  dynamoClient.get(projectParams, function (err,data) {
    if (err){
      console.log("Error: ",err);
    }else{
      var adminDel= false;
      var userDel = false;
      console.log('success getting project data:',data);
      let userArr = data.Item['Users']
      let adminArr = data.Item['Admin']
      console.log('')
      if (!userArr.length){
        setisUsersDel(true);
      }
      if (!adminArr.length){
        setisAdminDel(true);
      }
      for (let i = 0; i < userArr.length; i++){
        var userparams = {
          TableName: 'tool_jobs',
          Key: {
            username: userArr[i]
          }
        };
        dynamoClient.get(userparams, function(err,data){
          if (err){
            console.log('Error: ',err);
          }else{
            let memberProjs = data.Item['Projects']['member'];
            memberProjs = memberProjs.filter(element =>{
              return element !== inp_project_name
          });
                                 
          let newProjectsVal = {
            member:memberProjs,
            admin:data.Item['Projects']['admin'],
          } 
          var updateParams = {
            TableName: 'tool_jobs',
            Key:{
              username:userArr[i]
            },
            UpdateExpression: "set Projects  = :t",
            ExpressionAttributeValues: {
                ":t":newProjectsVal
            }
          }
          dynamoClient.update(updateParams, function(err,data){
            if (err){
              console.log('Error: ',err)
            } else {
              console.log('Success delete users', data)
              userDel=true;
            }
        });
          }
        });
      }

             
    for (let i = 0; i < adminArr.length; i++){
      var adminparams = {
        TableName: 'tool_jobs',
        Key: {
          username: adminArr[i]
        }
      }
      dynamoClient.get(adminparams, function(err,data){
        if (err){
          console.log('Error: ',err);
        }else{
          let adminProjs = data.Item['Projects']['admin'];
          adminProjs = adminProjs.filter(element =>{
            return element !==inp_project_name
        });


      let newProjectsVal = {
        member:data.Item['Projects']['member'],
        admin:adminProjs,
      } 
      var updateParams = {
        TableName: 'tool_jobs',
        Key:{
          username:adminArr[i]
        },
        UpdateExpression: "set Projects  = :t",
        ExpressionAttributeValues: {
            ":t":newProjectsVal
        }
      }
      dynamoClient.update(updateParams, function(err,data){
        if (err){
          console.log('Error: ',err)
        } else {
          console.log('Success delete admins', data)
          adminDel=true;
          
        }
    });
      }
    });
    }
    
    }


  });

});
  

}

const join = async(inp_project_name) =>{
  setProjectName(inp_project_name);
  window.localStorage.setItem("Projected",inp_project_name); 

  
}

  
const deleteProjTable = (inp_project_name) =>{
  console.log(inp_project_name);
  var projectParams = {
    TableName: 'projects_jobs',
    Key:{
      project_name:inp_project_name
    },
    UpdateExpression: "set #sub=:t",
    ExpressionAttributeValues: {
        ":t":true
    },
    ExpressionAttributeNames:{
      "#sub":"Archive"
  }
  }
  dynamoClient.update(projectParams, function(err,data){
    if (err){
      console.log("Error deleting from project table", err);
    } else{
      console.log("Succesfully archived project", data);  
      alert('Project has been deleted. Please refresh page to see changes')
      
      if (inp_project_name === project_name){
        setProjectName(null);
        window.localStorage.setItem("Projected",null);    
      }
    }
  });
}

  return (
    <ProjectsContext.Provider value = {[
      create, join, project_name, deleteProj,deleteProjTable
    ]}>
      {props.children}
    </ProjectsContext.Provider>
  )
}

export {Projects, ProjectsContext};