import React from 'react'
import './styles.css'
function Description() {
  return (
    <div>
    <div className='text'>
        {/* <h2>ATOM Toolkit</h2> */}
        Graphen Atom makes fundamental scientific progress down to the atom level. 
        These tools model from proteins, to protein-protein interaction and protein-environment interaction, 
        and to biological network and system biology viewpoints.Like a map that zooms to see in different scale,
        Graphen Atom provides a foundation of Digital Biology and helps to predict and explain how bio mechanisms function.
        
    </div>
    </div>
  )
}

export default Description