import React from 'react';
import { useMemo } from 'react';
// import {PluginContext} from 'molstar/lib/mol-plugin/context';
// import {DefaultPluginUISpec, PluginUISpec} from 'molstar/lib/mol-plugin-ui/spec';
// import {createPluginAsync} from 'molstar/lib/mol-plugin-ui/index';
// import {PluginConfig} from 'molstar/lib/mol-plugin/config';
// // import "molstar/lib/mol-plugin-ui/skin/light.scss";
// import { Viewer } from 'molstar/build/viewer/molstar';
import Molstar from 'molstar-react';
import './StructViewer.css'

function StructViewer(props) {
        const reprList = useMemo(() => [{
            type: 'ball+stick'
          }], []);
        console.log(props.link)
        return (
        <>
        <div className='heading'>
          <h3>Interactive Viewer</h3>
          <h5>Powered by Mol*</h5>
        </div>
        <div class='aspectwrapper'>
        <div class = 'content'>
            <Molstar showControls={true}  useInterface={true} showAxes = {true} url = {props.link} >    
            </Molstar>            
        </div>
        </div>
        </>
        );
    
}  
  
export default StructViewer;