import {React} from 'react'
import {ReactComponent as AtomTools} from '../assets/12_tools.svg'
import './Homepage.css'


function Tools() {
  return (
    <>
      <div class = "block">
        <AtomTools width = '150vh'></AtomTools>
      </div>
    </>
  )
}

export default Tools